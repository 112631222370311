
import { defineComponent } from "vue";
import PopUp from "../../components/popup/popup.vue";
import Counter from "../../components/counter/counter.vue";

const data = {
  popupActive: false,
  liveNow: false,
  loaded: false
};

export default defineComponent({
  name: "Home",
  data() {
    return data;
  },
  methods: {
    openPopup() {
      this.popupActive = true;
    }
  },
  components: {
    PopUp,
    Counter
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  }
});
