<template>
  <div class="wrapper">
    <router-view />
    <Footer />
  </div>
</template>

<style lang="scss">
@import "scss/all";
</style>

<script>
import Footer from "./components/footer/footer.vue";

const data = {
  loaded: false,
};

export default {
  name: "Axoft",
  data() {
    return data;
  },
  components: {
    Footer
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  }
};
</script>
