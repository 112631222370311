<template>
  <div class="pop-up" :class="{ 'pop-up--active': active }">
    <div class="pop-up__background" v-on:click="$emit('closePopup')"></div>
    <div class="pop-up__inner">
      <div class="pop-up__close-popup" v-on:click="$emit('closePopup')">
        <svg
          class="pop-up__close-popup-svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1849 12.1302L24.2603 1.05478L23.2056 0L12.1302 11.0754L1.05478 2.27536e-05L0 1.0548L11.0754 12.1302L0.00038008 23.2052L1.05516 24.26L12.1302 13.185L23.2052 24.26L24.26 23.2052L13.1849 12.1302Z"
            fill="#49A19C"
          />
        </svg>
      </div>
      <div class="pop-up__content">
        <h4 class="pop-up__subtitle">
          Jaarlijks Axoft Online Event
        </h4>
        <h3 class="pop-up__title">Alles draait om de eenvoud</h3>
        <p class="pop-up__intro">
          Tijdens ons jaarlijks Online Event praten we je bij over de laatste trends en ontwikkelingen op het gebied van
          IT, Security én Telecom. Het thema dit jaar is ‘alles draait om eenvoud’.
        </p>
        <p>
          Complexiteit is de vijand van productiviteit en beveiliging. Een moderne, gebruikersvriendelijke en veilige
          digitale werkomgeving moeten daarom eenvoudig zijn.
        </p>
        <p>Wil je op de hoogte zijn van de laatste trends en ontwikkelingen? Wil je weten welke nieuwe functies en
          diensten geïntroduceerd gaan worden? En wil je weten hoe je jouw digitale werkomgeving eenvoudig houdt
          waardoor jij en je medewerkers maximaal productief zijn en optimaal kunnen samenwerken en bereikbaar zijn?
          Kijk dan 15 december van 10:00-11:00 uur naar het Axoft Online Event.</p>
        <p>We zenden de sessie uit via een interactieve livestream en vragen zijn dan ook zeker welkom. Meld je <a
          href="https://www.axoft.nl/alles-draait-om-eenvoud/" target="_blank">direct aan</a>!</p>
        <h5 class="pop-up__paragraph-title">De volgende onderwerpen komen aan bod:</h5>
        <ul>
          <li>Welke security risico’s loopt mijn organisatie en hoe kan ik mijn organisatie beter beveiligen?</li>
          <li>Hoe zorg ik dat ik optimaal bereikbaar ben en kan samenwerken?</li>
          <li>Hoe ziet een moderne thuiswerkplek en vergaderruimte er uit?</li>
          <li>Wat is de visie van Microsoft op hybride werken?</li>
          <li>Hoe pakken andere bedrijven het aan?</li>
          <li>Wat zijn de belangrijkste trends en ontwikkelingen en wat kan ik er concreet mee?</li>
        </ul>
        <div class="button-wrapper">
          <a href="https://www.axoft.nl/alles-draait-om-eenvoud/" class="button" target="_blank">
            <span class="button__icon button__icon--arrow">
              <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M6.47852 5.63867C6.625 5.49219 6.625 5.28711 6.47852 5.14062L2.20117 0.804688C2.05469 0.658203 1.82031 0.658203 1.70312 0.804688L1.11719 1.39062C0.970703 1.50781 0.970703 1.74219 1.11719 1.88867L4.57422 5.375L1.11719 8.89062C0.970703 9.03711 0.970703 9.24219 1.11719 9.38867L1.70312 9.97461C1.82031 10.1211 2.05469 10.1211 2.20117 9.97461L6.47852 5.63867Z"
  fill="white" />
</svg>
            </span>
            <span class="button__text">
            Direct aanmelden
              </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "popup";
</style>

<script>
export default {
  name: "Pop Up",
  emits: ["closePopup"],
  props: {
    active: Boolean
  },
  methods: {}
};
</script>
