<template>
  <footer class="footer" :class="{ 'footer--inactive inactive' : loaded === false }">

    <div class="aninmate-elemeents-random__el" :class="[ 'aninmate-elemeents-random__el--'+index ]"
         v-for="index in maxIcons"  :key="index">
      <div class="footer-icon" :class="[ returnRandomIconClass(), 'footer-icon-'+index ]">
        <div class="footer-icon__text">
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 8L7 2L13 8"
              stroke="#49A19C"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "footer";
</style>

<script>
const data = {
  maxIcons: 400,
  footerIconChangetimeout: 250,
  loaded: false
};

export default {
  name: "Footer",
  data() {
    return data;
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);

    this.returnRandomIconClass();
    setInterval(() => {
      this.changeIconClass();
    }, this.footerIconChangetimeout);
  },

  methods: {
    changeIconClass() {
      let itemNumber = Math.floor(Math.random() * this.maxIcons);
      let el = document.querySelector(".footer-icon-" + itemNumber);
      let newClass = this.returnRandomIconClass();
      let newClasses = "footer-icon footer-icon-" + itemNumber + " " + newClass;
      el.classList = newClasses;
    },

    returnRandomIconClass() {
      const classes = [
        "footer-icon--right",
        "footer-icon--left",
        "footer-icon--top",
        "footer-icon--bottom"
      ];

      const randomClass = classes[Math.floor(Math.random() * classes.length)];
      return randomClass;
    }
  }

};
</script>
