<template>
  <div class="counter-wrapper">
    <div class="counter">
      <div class="counter__inner">
        <h3 class="counter__title">Het programma zal starten over:</h3>

        <div class="counter-timer">
          <div class="counter-timer__item counter-timer__item--days">
            <span class="counter-timer__item-int">
              <transition name="slide-fade">
              {{ times[0].time }}
              </transition>
            </span>
            <h6 class="counter-timer__item-title">Dagen</h6>
          </div>
          <div class="counter-timer__item counter-timer__item--hours">
            <span class="counter-timer__item-int">
              <transition name="slide-fade">
              {{ times[1].time }}
              </transition>
            </span>
            <h6 class="counter-timer__item-title">Uren</h6>
          </div>
          <div class="counter-timer__item counter-timer__item--minutes">
            <span class="counter-timer__item-int">
              <transition name="slide-fade">
              {{ times[2].time }}
              </transition>
            </span>
            <h6 class="counter-timer__item-title">Minuten</h6>
          </div>
          <div class="counter-timer__item counter-timer__item--seconds">
            <span class="counter-timer__item-int">
              <transition name="slide-fade">
                <span :key="times[3].time">
              {{ times[3].time }}
                  </span>
              </transition>
            </span>
            <h6 class="counter-timer__item-title">Seconden</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "counter";
</style>

<script>
import { useRoute } from 'vue-router'

const data = {
  times: [{ time: 1 }, { time: 1 }, { time: 1 }, { time: 1 }],
  timeinterval: undefined
};

export default {
  name: "Counter",
  emits: ["counterDone"],
  data() {
    return data;
  },
  computed: {
    getEndTime() {
      const route = useRoute()
      if(route.query.time) {
        return route.query.time;
      } else {
        return "December 15, 2021 10:00:00";
      }
    },
  },
  methods: {
    updateTimer: function() {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
      } else {
        clearTimeout(this.timeinterval);
        this.$emit('counterDone');
      }
    },
    getTimeRemaining: function() {
      let t = Date.parse(new Date(this.getEndTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor((t / 1000) % 60); //seconds
        this.times[2].time = Math.floor((t / 1000 / 60) % 60); //minutes
        this.times[1].time = Math.floor((t / (1000 * 60 * 60)) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time =
          this.times[2].time =
            this.times[1].time =
              this.times[0].time =
                0;
        this.progress = 0;
      }
    },
  },
  created() {
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  }
};
</script>
